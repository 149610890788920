import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Select, Space, Tooltip, message } from "antd";
import { DeleteOutlined, LoadingOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  getConversationListRequest,
  removeTagRequest,
} from "providers/MessengerProvider/slice";
import { setPopup } from "providers/GeneralProvider/slice";
import "./styles.less";

const FilterTag = () => {
  const dispatch = useDispatch();
  const { fonehouseTagsFilter, isRemovingTag } = useSelector(
    (state) => state.messenger
  );
  const [deletingTag, setDeletingTag] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();

  // Monitor changes to isRemovingTag to show success/error messages
  useEffect(() => {
    if (deletingTag && !isRemovingTag) {
      // Tag removal process completed (either success or failure)
      // We can determine if it was successful by checking if the tag still exists
      const tagStillExists = fonehouseTagsFilter.some(
        (tag) => tag.label === deletingTag && tag.value !== ""
      );

      if (!tagStillExists) {
        // Success case
        messageApi.success(`Đã xoá tag "${deletingTag}" thành công`);
      }

      setDeletingTag(null);
    }
  }, [isRemovingTag, deletingTag, fonehouseTagsFilter, messageApi]);

  const onSubmit = (data) => {
    dispatch(
      getConversationListRequest({
        limit: 20,
        page: 1,
        type: "FONEHOUSE_TO_USER",
        role: "fonehouse",
        tags: data,
      })
    );
  };

  const handleDeleteTag = (tagName) => {
    dispatch(
      setPopup({
        isOpen: true,
        data: {
          title: "Xoá tag",
          content:
            "Bạn có muốn xoá tag này khỏi hệ thống? Hành động này sẽ xoá tag khỏi tất cả các cuộc hội thoại.",
          okText: "OK",
          cancelText: "Huỷ",
          onOk: () => {
            setDeletingTag(tagName);
            dispatch(removeTagRequest({ tagName }));
          },
        },
      })
    );
  };

  // Create custom dropdown options with delete icons
  const customOptions = fonehouseTagsFilter.map((option) => {
    // Don't add delete icon to "Tất cả" option
    if (option.value === "") {
      return option;
    }

    const isDeleting = isRemovingTag && deletingTag === option.label;

    return {
      ...option,
      label: (
        <div className="tag-option">
          <span>{option.label}</span>
          {isDeleting ? (
            <LoadingOutlined className="deleting-icon" />
          ) : (
            <Tooltip title="Xoá tag">
              <DeleteOutlined
                className="delete-tag-icon"
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteTag(option.label);
                }}
              />
            </Tooltip>
          )}
        </div>
      ),
    };
  });

  return (
    <Space className="search-bar-form">
      {contextHolder}
      <Select
        placeholder="Lọc theo nhãn dán"
        style={{ borderRadius: 10, minWidth: 200 }}
        options={customOptions}
        onChange={onSubmit}
        notFoundContent={
          isRemovingTag ? (
            <div className="loading-container">
              <LoadingOutlined /> Đang xử lý...
            </div>
          ) : null
        }
      />
    </Space>
  );
};

FilterTag.propTypes = {
  filterFields: PropTypes.array,
  children: PropTypes.object,
  initVal: PropTypes.object,
  isOnChangeSearch: PropTypes.bool,
};

export default FilterTag;
