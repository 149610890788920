import { useEffect, useRef, useCallback, useState } from "react";
import { Row, Typography, Button } from "antd";
import i18n from "i18n";
import _invoke from "lodash/invoke";
import _isEmpty from "lodash/isEmpty";
import _get from "lodash/get";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { usePrevious } from "hooks";
import {
  getConversationListRequest,
  conversationSelectors,
  messageSelectors,
  getMessageListRequest,
  sendMessageRequest,
  updateMessage,
  getConversationDetailRequest,
  seenConversationRequest,
  firstGetListMessageFalse,
  triggerModalTag,
  getFonehouseTagsRequest,
  sendingMessage,
  finishSendMessage,
} from "providers/MessengerProvider/slice";
import { getBillingListRequest } from "providers/BillingProvider/slice";
import "./styles.less";
import SocketClient from "utils/socket";
import Helper from "utils/helpers";
import { LoadingOutlined } from "@ant-design/icons";
import LoadingIndicator from "components/LoadingIndicator";
import MessageInput from "./MessageList/MessageInput";
import MessageList from "./MessageList";
import ConversationHeader from "./MessageList/ConversationHeader";
import ConversationList from "./ConversationList";
import CreateTagModal from "./CreateTagModal";
import SetTagModal from "./SetTagModal";
import FilterTag from "./FilterTag";

const Messenger = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [uploading, setUploading] = useState(false);
  const formRef = useRef();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const currentUserId = _get(currentUser, "_id");
  const currentUserRole = _get(currentUser, "role");
  const { conversationId } = useParams();
  const conversationIds = useSelector(conversationSelectors.selectIds);
  const conversationEntities = useSelector(
    conversationSelectors.selectEntities
  );
  const conversationHasMore = useSelector(
    (state) => state.messenger.conversations.hasMore
  );
  const conversationPage = useSelector(
    (state) => state.messenger.conversations.page
  );
  const opponentUserId = _get(
    conversationEntities[conversationId],
    "member[0]"
  );
  const billingList = useSelector((state) => state.billingList.docs);
  const latestBill = billingList[0];

  const messageIds = useSelector(messageSelectors.selectIds);
  const messageEntities = useSelector(messageSelectors.selectEntities);
  const messageHasMore = useSelector(
    (state) => state.messenger.messages.hasMore
  );
  const lastMessageTime = useSelector(
    (state) => state.messenger.messages.lastMessageTime
  );
  const firstGetList = useSelector((state) => state.messenger.firstGetList);
  const isGettingMessageList = useSelector(
    (state) => state.messenger.isGettingMessageList
  );
  const { isRemovingTag } = useSelector((state) => state.messenger);
  const firstConversationId = conversationIds[0];
  const prevFirstConversationId = usePrevious(firstConversationId);
  const { socket } = SocketClient;

  useEffect(() => {
    dispatch(getFonehouseTagsRequest());
  }, [dispatch]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!socket?.connected) {
      socket?.connect();
    }

    if (currentUserId && socket) {
      socket.emit("identity");
      socket.emit("subscribe", { room: conversationId });
      socket.on("new_message", (data) => {
        console.log("On new message", data);
        const dataConversationId = _get(data, "message.conversationId");
        if (dataConversationId === conversationId) {
          dispatch(finishSendMessage());
          dispatch(updateMessage({ ...data }));
        }
      });
      return () => {
        socket.off("new_message");
      };
    }
  }, [conversationId, currentUserId, dispatch, socket]);

  useEffect(() => {
    if (currentUserId && socket) {
      socket.on("conversation-change", (data) => {
        console.log("On conversation change", data);
        dispatch(getConversationDetailRequest(data));
      });
    }
  }, [currentUserId, dispatch, socket]);

  useEffect(() => {
    if (!prevFirstConversationId && firstConversationId && !conversationId) {
      navigate(`/fonehouse/messengers/${firstConversationId}`, {
        replace: true,
      });
    }
  }, [conversationId, firstConversationId, navigate, prevFirstConversationId]);

  useEffect(() => {
    dispatch(
      getConversationListRequest({
        limit: 20,
        page: 1,
        type: "FONEHOUSE_TO_USER",
        role: "fonehouse",
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (conversationId) {
      dispatch(seenConversationRequest({ conversationId }));
      dispatch(getMessageListRequest({ limit: 500, conversationId }));
    }
  }, [conversationId, dispatch]);

  useEffect(() => {
    if (opponentUserId) {
      dispatch(
        getBillingListRequest({ userId: opponentUserId, limit: 1, page: 1 })
      );
    }
  }, [dispatch, opponentUserId]);

  const handleGetMessageList = useCallback(() => {
    dispatch(
      getMessageListRequest({
        limit: 15,
        time: lastMessageTime,
        conversationId,
      })
    );
  }, [conversationId, dispatch, lastMessageTime]);

  const handleGetConversationList = useCallback(() => {
    dispatch(
      getConversationListRequest({
        limit: 20,
        page: conversationPage + 1,
        type: "FONEHOUSE_TO_USER",
        role: "fonehouse",
      })
    );
  }, [conversationPage, dispatch]);

  const handleConversationCellClick = useCallback(
    (_conversationId) => {
      dispatch(firstGetListMessageFalse());

      navigate(`/fonehouse/messengers/${_conversationId}`, { replace: true });
    },
    [navigate]
  );

  const handleSendMessage = useCallback(
    async (values) => {
      try {
        dispatch(sendingMessage());
        formRef.current.resetForm();
        let attachments;
        if (!_isEmpty(values.attachments)) {
          let promises = [];
          const imageFiles = values.attachments.filter(
            (file) => file.type === "image"
          );
          const videoFiles = values.attachments.filter(
            (file) => file.type === "video"
          );
          if (!_isEmpty(videoFiles)) {
            promises = promises.concat(
              videoFiles.map((file) => Helper.uploadVideo(file))
            );
          }
          if (!_isEmpty(imageFiles)) {
            promises = promises.concat(
              imageFiles.map((file) => Helper.uploadFile(file))
            );
          }
          setUploading(true);
          const result = await Promise.all(promises);
          attachments = result.map((res) => ({
            type: res.attachmentType,
            data:
              res.attachmentType === "image"
                ? { imageUrl: res.url }
                : { videoUrl: res.url },
          }));
          setUploading(false);
        }
        const trimmedMessage = _invoke(values.message, "trim");
        if (trimmedMessage || !_isEmpty(attachments)) {
          dispatch(
            sendMessageRequest({
              type: "text",
              text: trimmedMessage || null,
              conversationId,
              attachments,
            })
          ).then((result) => {
            socket.emit("new_message_admin", {
              room: conversationId,
              messageId: result._id,
            });
          });
        }
      } catch (error) {
        setUploading(false);
        dispatch(finishSendMessage());
        console.error(error);
      }
    },
    [conversationId, dispatch, socket]
  );

  const handleShowModalTag = useCallback(() => {
    dispatch(
      triggerModalTag({
        visible: true,
        conversationId,
        conversationEntities,
      })
    );
  }, [dispatch, conversationEntities, conversationId]);

  return (
    <div className="messenger-page">
      {isRemovingTag && <LoadingIndicator />}
      <Row
        className="bill-list-header"
        justify={"space-between"}
        style={{ alignItems: "center" }}
      >
        <Typography.Title level={4}>
          {i18n.t("messenger.pageTitle")}
        </Typography.Title>

        <Button size="large" type="primary" onClick={handleShowModalTag}>
          Tạo nhãn dán
        </Button>
      </Row>
      <FilterTag />
      <div className="message-page-content">
        <ConversationList
          activeConversationId={conversationId}
          onConversationCellClick={handleConversationCellClick}
          conversationIds={conversationIds}
          hasMore={conversationHasMore}
          getConversationList={handleGetConversationList}
          conversationEntities={conversationEntities}
          currentUserId={currentUserId}
        />
        <div className="conversation-container">
          <ConversationHeader
            conversationDetail={conversationEntities[conversationId]}
            latestBill={latestBill}
          />
          <MessageList
            firstGetList={firstGetList}
            isGettingMessageList={isGettingMessageList}
            hasMore
            getMessageList={handleGetMessageList}
            messageIds={messageIds}
            currentUserId={currentUserId}
            currentUserRole={currentUserRole}
            messageEntities={messageEntities}
          />
          <MessageInput
            conversationId={conversationId}
            ref={formRef}
            uploading={uploading}
            onClick={handleSendMessage}
          />
        </div>
      </div>
      <CreateTagModal />
      <SetTagModal />
    </div>
  );
};

export default Messenger;
