import { Outlet } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import _get from "lodash/get";
import DashboardLayout from "components/Layout";
import { toggleCollapseMenu } from "providers/GeneralProvider/slice";
import { logoutRequest } from "providers/AuthProvider/slice";
import AccountMenu from "components/Layout/AccoutMenu";

const Dashboard = () => {
  const collapseMenu = useSelector((state) =>
    _get(state, "general.collapseMenu")
  );
  const currentUser = useSelector((state) => _get(state, "auth.currentUser"));
  const dispatch = useDispatch();
  const toggleMenu = (data) => {
    console.log(data, "data");
    dispatch(toggleCollapseMenu(data));
  };
  const logOut = () => {
    dispatch(logoutRequest());
  };
  console.log(currentUser, "currentUser");
  return (
    <>
      <DashboardLayout
        menu={() => <AccountMenu logOut={logOut} />}
        currentUser={currentUser}
        collapseMenu={collapseMenu}
        toggleCollapseMenu={toggleMenu}
      >
        <Outlet />
      </DashboardLayout>
    </>
  );
};
export default Dashboard;
