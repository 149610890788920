// import React from 'react';
import i18n from "i18n";

export default [
  {
    _to: "/home",
    text: "CMS Admin",
    key: "cms_admin",
  },
  {
    _to: "/user_transactions",
    text: "Quản lý giao dịch",
    key: "user_transactions",
  },
  {
    _to: "/cms_admin_report_manager",
    text: "Quản lý báo cáo",
    key: "cms_admin_report_manager",
  },
  {
    _to: "/liquid_viego_manager",
    text: "Quản lý thanh khoản Viego",
    key: "liquid_viego_manager",
  },
  {
    _to: "/reward_point_manager",
    text: "Quản lý điểm thưởng",
    key: "reward_point_manager",
  },
  {
    _to: "/comment_and_rating",
    text: "Bình luận & Đánh giá",
    key: "comment_and_rating",
  },
  {
    _to: "/identify_user",
    text: "Xác minh danh tính",
    key: "identify_user",
  },
  {
    _to: "/cms_admin_manager",
    text: "Quản lý Admin",
    key: "cms_admin_manager",
  },
  {
    _to: "/cms_viego_support",
    text: "Viego Support",
    key: "cms_viego_support",
    subMenu: [
      {
        key: "viego_messengers",
        text: i18n.t("menu.messengers"),
        _to: "/viego_messengers",
      },
    ],
  },
  {
    _to: "/banners",
    text: "Set Banner",
    key: "banners",
  },
  {
    _to: "/support",
    text: "Hỗ trợ",
    key: "support",
  },
  {
    key: "news",
    _to: "/news",
    text: i18n.t("menu.news"),
  },
  {
    key: "visa",
    _to: "/visa",
    text: "Visa",
  },
  {
    _to: "/supermarket",
    text: i18n.t("menu.supermarket"),
    key: "supermarket",
  },
  {
    _to: "/restaurant",
    text: i18n.t("menu.restaurant"),
    key: "restaurant",
  },
  {
    key: "entertainment",
    _to: "/entertainment",
    text: i18n.t("menu.entertainment"),
  },
  {
    key: "sim",
    _to: "/sim",
    text: "Sim",
  },
  {
    key: "travel",
    _to: "/travel",
    text: "Du lịch",
  },
  {
    key: "shipper",
    _to: "/shipper",
    text: "Vận chuyển",
  },
  {
    key: "car-and-old-stuff",
    _to: "/car-and-old-stuff",
    text: "Xe - đồ cũ",
  },
  {
    key: "cosmetic",
    _to: "/cosmetic",
    text: i18n.t("menu.cosmetic"),
  },
  {
    key: "jobs",
    _to: "/jobs",
    text: i18n.t("menu.jobs"),
  },
  {
    key: "tutorial",
    text: i18n.t("menu.guideFonehouse"),
    _to: "/guide-fonehouse",
  },
  {
    text: i18n.t("menu.fonehouse"),
    _to: "/fonehouse",
    subMenu: [
      {
        key: "list_bill",
        text: i18n.t("menu.list_bill"),
        _to: "/billing_list",
      },
      {
        key: "products",
        text: i18n.t("menu.products"),
        _to: "/products",
      },
      {
        key: "messengers",
        text: i18n.t("menu.messengers"),
        _to: "/messengers",
      },
      {
        key: "checking_insurance",
        text: i18n.t("menu.checking_insurance"),
        _to: "/checking_insurance",
      },
      {
        key: "newsFonehouse",
        text: i18n.t("menu.newsFonehouse"),
        _to: "/news-fonehouse",
      },
      {
        key: "statisticalFonehouse",
        text: i18n.t("menu.statisticalFonehouse"),
        _to: "/statistical-fonehouse",
      },
      {
        key: "moneyManagementFonehouse",
        text: i18n.t("menu.moneyManagementFonehouse"),
        _to: "/moneyManagement-fonehouse",
      },
      {
        key: "historyEditorFonehouse",
        text: i18n.t("menu.historyEditor"),
        _to: "/historyEditor-fonehouse",
      },
      {
        key: "accessories",
        text: i18n.t("menu.accessories"),
        _to: "/accessories",
      },
    ],
  },
  {
    _to: "/registerBusiness",
    text: i18n.t("menu.registerBusiness"),
    key: "registerBusiness",
  },

  // {
  //   _to: '/thong-ke',
  //   text: 'Thống kê',
  // },
  // {
  //   _to: '/kiem-tra-bao-hanh',
  //   text: 'Kiểm tra bảo hành',
  // },
];
