import axios from "axios";
import _ from "lodash";
import i18n from "i18n";
import Helper from "./helpers";
import { API_URL } from "./config";

const getAxiosInstance = () => {
  const token = Helper.getAuthToken();
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const headers = {
    // 'X-Parse-Application-Id': APP_ID,
    // 'X-Parse-REST-API-Key': REST_KEY,
    "vg-ua": "cms",
    ...(token ? { "vg-auth-token": token, "vg-timezone": timeZone } : {}),
    "vg-sid": Helper.getSessionId(),
  };

  const axiosInstance = axios.create({
    baseURL: API_URL,
    headers,
  });

  axiosInstance.interceptors.response.use(
    (response) => {
      const arr = [200, 201];
      if (arr.indexOf(response.status) !== -1) {
        const result = response.data;
        result.statusCode = response.status;
        return response.data;
      }

      return Promise.reject(response);
    },
    (error) => {
      const errorObj = _.get(error, "response.data", {});
      console.log(errorObj, "errorObj");

      if (error.response.status === 403 || error.response.status === 401) {
        Promise.reject(errorObj);
        // Helper.removeAuthToken();
        // window.location = "/login";
      }
      if (errorObj) {
        Promise.reject(errorObj);
        Helper.toastr("Lỗi", errorObj?.errors?.[0]?.message, "error");
      }
      if (error.message && error.message !== "Network Error") {
        const code = _.get(error, "response.data.code", null);
        const message = _.get(error, "response.data.details[0].message", null);
        const statusText = _.get(error, "response.statusText");
        if (code || message) {
          const errorObjs = _.get(error, "response.data.details[0]", {});

          return Promise.reject(errorObjs);
        }
        return Promise.reject(statusText || "undefined_error");
      }

      return Promise.reject(i18n.t("alert.noNetwork"));
    }
  );

  return axiosInstance;
};

const request = (url, data = {}, options = {}) => {
  const { method = "POST" } = options;
  try {
    const API = getAxiosInstance();
    switch (method) {
      case "GET":
        return API.get(url, { params: data });
      case "GET_BODY":
        return API.get(url, data);
      case "PUT":
        return API.put(url, data);
      case "DELETE":
        return API.delete(url, { params: data });
      case "DELETE_BODY":
        return API.delete(url, { data });
      case "PATCH":
        return API.patch(url, data);
      default:
        return API.post(url, data);
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export default request;
