import { useEffect, memo, useState } from "react";
import _ from "lodash";
import _isEmpty from "lodash/isEmpty";
import _isEqual from "lodash/isEqual";
import { Layout, Menu, Affix, Image, Skeleton } from "antd";
import PropTypes from "prop-types";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import LogoIcon from "assets/images/vg_logo_small.svg";
import { getCountNotSeenRequest } from "providers/NewsProvider/slice";
import { getCountNotReadSupportRequest } from "providers/SupportProvider/slice";
import "./styles.less";
import { ROLES } from "utils/constants";
import SocketClient from "utils/socket";
import MenuRoutes from "./SideMenuRoutes";
import InformationBox from "./InformationBox";

const { SubMenu } = Menu;
const { Sider } = Layout;

const CustomSideMenu = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const [unreadConversations, setUnreadConversations] = useState({
    FONEHOUSE_TO_USER: 0,
    VIETGO_TO_USER: 0,
  });
  const userRoles = useSelector((state) =>
    _.get(state, "auth.currentUser.roles", [])
  );
  const userRole = useSelector((state) =>
    _.get(state, "auth.currentUser.role")
  );
  const roleChat = useSelector((state) =>
    _.get(state, "auth.currentUser.roleChat")
  );
  const unreadNews = useSelector((state) => _.get(state, "news.unreadNews"));
  const currentUser = useSelector((state) => _.get(state, "auth.currentUser"));
  const currentUserId = _.get(currentUser, "_id");
  const allSupportUnread = useSelector((state) =>
    _.get(state, "support.allSupportUnread")
  );
  const totalRating = useSelector((state) =>
    _.get(state, "ratingComment.totalRating")
  );
  const totalReport = useSelector((state) =>
    _.get(state, "reportManager.totalReport")
  );

  const canGetCountNotReadSupport = [ROLES.SUPER_ADMIN, ROLES.ADMIN].includes(
    userRole
  );
  const canGetCountNotSeen = userRoles.includes("news");
  const { socket } = SocketClient;

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!socket?.connected) {
      socket?.connect();
    }

    if (currentUserId && socket) {
      socket.emit("subscribe", currentUserId);
      socket.emit("unread-conversations", {
        room: currentUserId,
      });
      socket.on("unread-conversations", (data) => {
        if (
          !_isEqual(unreadConversations, _.get(data, "unreadConversations", {}))
        ) {
          setUnreadConversations(data.unreadConversations);
        }
      });
      return () => {
        socket.off("unread-conversations");
      };
    }
  }, [currentUserId, dispatch, socket, unreadConversations]);

  // useEffect(() => {
  //   dispatch(getUnreadRatingRequest());
  //   dispatch(getUnreadReportRequest());
  // }, [dispatch]);
  useEffect(() => {
    if (canGetCountNotSeen) {
      dispatch(getCountNotSeenRequest());
    }
  }, [dispatch, canGetCountNotSeen]);

  useEffect(() => {
    if (canGetCountNotReadSupport) {
      dispatch(getCountNotReadSupportRequest());
    }
  }, [dispatch, canGetCountNotReadSupport]);
  const renderUnreadMenuNumber = (menuKey) => {
    switch (menuKey) {
      case "news":
        return unreadNews;
      case "comment_and_rating":
        return totalRating;
      case "support":
        return allSupportUnread;
      case "cms_admin_report_manager":
        return totalReport;
      case "messengers":
        return unreadConversations.FONEHOUSE_TO_USER;
      case "viego_messengers":
        return unreadConversations.VIETGO_TO_USER;
      default:
        return null;
    }
  };
  const splitLocationArr = location.pathname
    .split("/")
    .reduce((splitLocation, route, index) => {
      if (index > 0) {
        const slashLocation = `/${route}`;
        splitLocation.push(slashLocation);
      }
      return splitLocation;
    }, []);

  const { collapseMenu } = props;
  const renderMenuItems = (menuItems) => {
    const resultJSX = [];

    // eslint-disable-next-line consistent-return
    menuItems.forEach((menu) => {
      if (
        ["/liquid_viego_manager"].includes(menu._to) &&
        userRole !== "super_admin"
      ) {
        return null;
      }
      if (
        [
          "/fonehouse",
          "/registerBusiness",
          "/banners",
          "/support",
          "/cms_viego_support",
        ].includes(menu._to) &&
        userRole === "user"
      ) {
        return null;
      }
      if (_.has(menu, "subMenu")) {
        resultJSX.push(
          <SubMenu
            key={menu.key || menu._to}
            disabled={menu.disabled}
            title={menu.text}
            icon={menu.icon}
          >
            {renderMenuItems(menu.subMenu, true)}
          </SubMenu>
        );
      } else {
        console.log(menu.key, "menu.key");
        console.log(userRoles, "userRoles");
        if (
          userRole === "user" &&
          !userRoles.includes(menu.key) &&
          [
            "news",
            "visa",
            "supermarket",
            "restaurant",
            "entertainment",
            "tutorial",
            "cosmetic",
            "sim",
            "travel",
            "shipper",
            "car-and-old-stuff",
            "jobs",
          ].includes(menu.key)
        ) {
          return null;
        }
        if (
          userRole === "admin" &&
          !userRoles.includes(menu.key) &&
          ["cms_admin", "cms_admin_manager"].includes(menu.key)
        ) {
          return null;
        }
        if (
          userRole !== "super_admin" &&
          userRole !== "admin" &&
          [
            "cms_admin",
            "cms_admin_manager",
            "cms_viego_support",
            "reward_point_manager",
            "user_transactions",
            "cms_admin_report_manager",
            "comment_and_rating",
            "identify_user",
          ].includes(menu.key)
        ) {
          return null;
        }
        if (
          userRole !== "super_admin" &&
          !roleChat &&
          (menu.key === "messengers" || menu.key === "viego_messengers")
        ) {
          return null;
        }
        resultJSX.push(
          <Menu.Item key={menu._to} icon={menu.icon} disabled={menu.disabled}>
            {menu.text}
            {(menu.key === "news" && unreadNews > 0) ||
            (menu.key === "comment_and_rating" && totalRating > 0) ||
            (menu.key === "cms_admin_report_manager" && totalReport > 0) ||
            (menu.key === "messengers" &&
              unreadConversations.FONEHOUSE_TO_USER > 0) ||
            (menu.key === "viego_messengers" &&
              unreadConversations.VIETGO_TO_USER > 0) ||
            (menu.key === "support" && allSupportUnread > 0) ? (
              <div className="unread-news-number">
                {renderUnreadMenuNumber(menu.key)}
              </div>
            ) : (
              ""
            )}
          </Menu.Item>
        );
      }
    });

    return resultJSX;
  };

  const onMenuItemClick = ({ keyPath }) => {
    const revkeyPath = keyPath.reverse();
    const pathString = revkeyPath.join("");
    if (pathString !== pathname) {
      navigate(pathString);
    }
  };

  return (
    <Affix offsetTop={0} style={{ zIndex: 10 }} className="menu-wrapper">
      <Sider
        width={props.width}
        className="aside-menu"
        trigger={null}
        collapsible
        collapsed={collapseMenu}
      >
        <div className="side-menu-logo">
          <Image src={LogoIcon} size={52} preview={false} />
          <div className="logo-text">Vie-Go CMS</div>
        </div>
        <Menu
          mode="inline"
          selectedKeys={splitLocationArr}
          style={{ borderRight: 0 }}
          onClick={onMenuItemClick}
          defaultOpenKeys={splitLocationArr}
        >
          {!_isEmpty(currentUser) ? renderMenuItems(MenuRoutes) : <Skeleton />}
        </Menu>
        <InformationBox />
      </Sider>
    </Affix>
  );
};

CustomSideMenu.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
  collapseMenu: PropTypes.bool,
  toggleCollapseMenu: PropTypes.func,
  width: PropTypes.number,
};

export default memo(CustomSideMenu);
