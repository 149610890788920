import i18n from "i18n";

export const INSTALLATION_ID = "@youragent_installationId";
export const MESSAGE = {
  CHANGE_PASSWORD_SUCCESS: "Change password successfully",
  RESET_PASSWORD_SUCCESS: "Please check your email",
  REQUIRED: "必須項目です",
  INVALID_EMAIL: "このメールアドレスは無効です",
  INVALID_WEBSITE: "無効なURLです",
  INVALID: "無効です",
  CONFIRM_PASSWORD_NOT_MATCH: "The confirm password does not match",
  PASSWORD_RULES:
    "アルファベットの大文字小文字を含む英数字8文字以上で設定してください",
  INVALID_VIDEO_FILE_TYPE:
    "Valid video file types include .mp4, .mkv, .mov, .webm",
  EMAIL_IS_ALREADY_REGISTERED: "このメールアドレスは登録済みです",
  UPLOAD_FAIL: "アップロードできません",
  ALPHABET_REQUIRED: "Name may include only alphabetical characters.",
  MAX_10_CHARACTERS: "Max length is 10 characters",
  CREATE_AGENT_SUCCESS: "You created the agent successfully",
  EDIT_AGENT_SUCCESS: i18n.t("alert.updateSuccess"),
  SEND_VERIFIED_EMAIL_SUCCESS: i18n.t("alert.verifiedEmailSuccess"),
};
export const ROLES = {
  ADMIN: "admin",
  SUPER_ADMIN: "super_admin",
  FONEHOUSE: "fonehouse",
  MANAGER: "manager",
  USER: "user",
};
export const REG_EXP = {
  // eslint-disable-next-line no-useless-escape
  PASSWORD_POLICY:
    // eslint-disable-next-line no-useless-escape
    /^(?=.*[a-z])(?=.*[A-Z])[A-Za-z\d!\"#$%&'()*+,-./:;<=>?@^_`{|}~\[\]]{8,}$/,
};
export const INSURANCE_STATUS = [
  {
    label: "Hết hạn",
    value: 1,
  },
  {
    label: "Còn hạn",
    value: 0,
  },
];

export const LIMIT = {
  MAX_LIMIT: 10000,
  AGENT_LIST: 10,
};
export const USER_STATUS = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
};
export const ERRORS = {
  INVALID_SESSION_TOKEN: {
    code: 209,
    message: "Invalid session token!",
  },
  EXISTED_EMAIL: {
    code: 202,
    message: "Account already exists for this username.",
  },
};

export const VIEW_PORT_SIZE = {
  MOBILE: 768,
};

export const BILL_STATUS = {
  new: {
    value: "new",
    label: "Đơn hàng mới",
  },
  cancel: {
    value: "cancel",
    label: "Đã huỷ",
  },
  ordered: {
    value: "success",
    label: "Đã giao",
  },
  waiting: {
    value: "shipped",
    label: "Đã gửi hàng",
  },
  daibiki: {
    value: "Daibiki",
    label: "Daibiki thanh toán tại nhà",
  },
  cash: {
    value: "cash",
    label: "Giao dịch tại cửa hàng",
  },
  transfer: {
    value: "transfer",
    label: "Chuyển tiền trước",
  },
};

export const ARTICLE_ROLES = {
  news: {
    value: "news",
    label: "Tin tức",
  },
  supermarket: { value: "supermarket", label: "Siêu thị" },
  jobs: { value: "jobs", label: "Việc làm" },
  transport: { value: "transport", label: "Vận chuyển" },
  tutorial: { value: "tutorial", label: "Cẩm nang" },
  rent: { value: "rent", label: "Thuê nhà" },
  visa: { value: "visa", label: "Visa" },
  restaurant: { value: "restaurant", label: "Nhà hàng" },
  tourist: { value: "tourist", label: "Du lịch" },
  entertainment: { value: "entertainment", label: "Giải trí" },
  fonehouse: {
    value: "fonehouse",
    label: "Fonehouse",
  },
  cosmetic: { value: "cosmetic", label: "Làm đẹp" },
};
export const ROLE_ADMIN_FONEHOUSE = [
  {
    label: "Admin FoneHouse",
    value: "admin",
  },
];
export const ROLES_LIST_PERMISSION = [
  {
    label: "Tin tức",
    value: "news",
  },

  {
    label: "Siêu thị",
    value: "supermarket",
  },
  {
    label: "Việc làm",
    value: "jobs",
  },
  {
    label: "Vận chuyển",
    value: "transport",
  },
  {
    label: "Cẩm nang",
    value: "tutorial",
  },
  {
    label: "Thuê nhà",
    value: "rent",
  },
  {
    label: "Visa",
    value: "visa",
  },
  {
    label: "Nhà hàng",
    value: "restaurant",
  },
  {
    label: "Du lịch",
    value: "tourist",
  },
  {
    label: "Giải trí",
    value: "entertainment",
  },
  {
    label: "Giải trí",
    value: "entertainments",
  },

  {
    label: "Làm đẹp",
    value: "cosmetic",
  },
  {
    label: "Xe đồ cũ",
    value: "car_and_old_stuff",
  },
  {
    label: "Khác",
    value: "other",
  },
];
export const ROLES_LIST = [
  {
    label: "Tin tức",
    value: "news",
  },

  {
    label: "Siêu thị",
    value: "markets",
  },
  {
    label: "Việc làm",
    value: "jobs",
  },
  {
    label: "Vận chuyển",
    value: "transport",
  },
  {
    label: "Cẩm nang",
    value: "tutorial",
  },
  {
    label: "Thuê nhà",
    value: "rent",
  },
  {
    label: "Visa",
    value: "visa",
  },
  {
    label: "Nhà hàng",
    value: "restaurants",
  },
  {
    label: "Du lịch",
    value: "tourist",
  },
  {
    label: "Giải trí",
    value: "entertainment",
  },
  {
    label: "Giải trí",
    value: "entertainments",
  },
  {
    label: "Làm đẹp",
    value: "cosmetics",
  },
  {
    label: "Làm đẹp",
    value: "cosmetic",
  },
  {
    label: "Xe đồ cũ",
    value: "car_and_old_stuff",
  },
  {
    label: "Khác",
    value: "other",
  },
];

export const NEWS_ROLES = {
  confirm: {
    value: "approved",
    label: "Đã duyệt",
  },
  waiting: {
    value: "waiting",
    label: "Đang chờ duyệt",
  },
  refuse: {
    value: "refuse",
    label: "Từ chối",
  },
};

export const VISA_ROLES = {
  confirm: {
    value: "approved",
    label: "Đã duyệt",
  },
  waiting: {
    value: "waiting",
    label: "Đang chờ duyệt",
  },
  refuse: {
    value: "refuse",
    label: "Từ chối",
  },
};

export const CATEGORY_LIST = [
  {
    label: "Iphone",
    value: "Iphone",
  },
  {
    label: "iPad",
    value: "ipad",
  },
  {
    label: "Airpod",
    value: "aipod",
  },
  {
    label: "Macbook",
    value: "macbook",
  },

  {
    label: "Đồng hồ",
    value: "watch",
  },
  {
    label: "Samsung",
    value: "samsung",
  },
  {
    label: "Android",
    value: "android",
  },
  {
    label: "PC Laptop",
    value: "pclaptop",
  },
  {
    label: "Vòng huyết áp",
    value: "band",
  },
  {
    label: "Phụ kiện",
    value: "accessories",
  },
];
export const PRODUCT_PARAMS = {
  PRODUCT_ID: "productId",
  IMEI: "imei",
};
export const SIM_PARAMS = {
  customOrderId: "customOrderId",
  phone: "phone",
};

export const PRODUCT_TAG = {
  HOT: "HOT",
  HIGHLIGHTS: "HIGHLIGHTS",
  BUY_NOW: "BUY_NOW",
  FONEHOUSE: "FONEHOUSE",
  EMPTY: "EMPTY",
  ACQUISITION: "ACQUISITION",
};

export const BILL_TYPE_TAG = {
  FACEBOOK: "facebook",
  OTHER: "other",
};

export const BILL_TYPE_TAG_TEXT = {
  [BILL_TYPE_TAG.FACEBOOK]: "Facebook",
};

export const TRANSACTION_TAG = {
  DAIBIKI: "Daibiki",
  TRANSFER: "transfer",
  CASH: "cash",
};

export const MONEY_MANAGEMENT_TRANSFER_TYPE = {
  transfer: "Chuyển khoản",
  cash: "Tiền mặt",
};

export const TRANSACTION_TAG_TEXT = {
  Daibiki: "Daibiki",
  transfer: "Trả trước",
  cash: "GD cửa hàng",
};

export const BILL_TAG = {
  NEW: "new",
  SUCCESS: "success",
  CANCEL: "cancel",
  SHIPPED: "shipped",
};

export const BILL_TAG_TEXT = {
  [BILL_TAG.NEW]: "Đơn hàng mới",
  [BILL_TAG.SUCCESS]: "Đã giao",
  [BILL_TAG.SHIPPED]: "Đã gửi hàng",
  [BILL_TAG.CANCEL]: "Đã huỷ",
};

export const PRODUCT_TAG_TEXT = {
  [PRODUCT_TAG.HOT]: "HOT",
  [PRODUCT_TAG.HIGHLIGHTS]: "Nổi bật",
  [PRODUCT_TAG.BUY_NOW]: "Mua ngay",
  [PRODUCT_TAG.FONEHOUSE]: "Fonehouse",
  [PRODUCT_TAG.EMPTY]: "Trống",
  [PRODUCT_TAG.ACQUISITION]: "Thu mua",
};
export const PROMOTION_ACTIVE = [
  {
    value: true,
    label: "Bật giảm giá",
  },
  {
    value: false,
    label: "Tắt giảm giá",
  },
];

export const ALLOW_PAY_VIEGO = [
  {
    value: true,
    label: "Cho phép",
  },
  {
    value: false,
    label: "Tắt",
  },
];
export const PRODUCT_SEARCH_PARAM = {
  [PRODUCT_PARAMS.PRODUCT_ID]: "Mã sản phẩm",
  [PRODUCT_PARAMS.IMEI]: "IMEI",
};
export const SIM_SEARCH_PARAM = {
  [SIM_PARAMS.customOrderId]: "Mã đơn hàng",
  [SIM_PARAMS.phone]: "Số điện thoại",
};

export const CURRENCY = {
  JPY: "JPY",
  VND: "VND",
};
export const TAG_LIST = [
  {
    label: "HOT",
    value: "hot",
  },
  {
    label: "nổi bật",
    value: "noibat",
  },
  {
    label: "mua ngay",
    value: "mua ngay",
  },
];

export const BUSINESS_LIST = {
  confirm: {
    value: "approved",
    label: "Đã duyệt",
  },
  waiting: {
    value: "waiting",
    label: "Đang chờ duyệt",
  },
  refuse: {
    value: "refuse",
    label: "Từ chối",
  },
};

export const SUPER_MARKET_LIST = {
  active: {
    value: "active",
    label: "Hoạt động",
  },
  lock: {
    value: "lock",
    label: "Khoá",
  },
};

export const RESTAURANT_LIST = {
  active: {
    value: "active",
    label: "Hoạt động",
  },
  lock: {
    value: "lock",
    label: "Khoá",
  },
};
export const NEWS_TAG = {
  NEWS: "Tin tức",
  ACQUISITION: "Thu mua",
};
export const TIME_FRAME_TYPE = {
  ONE: "9 - 12 giờ",
  TWO: "12 - 14 giờ",
  THREE: "14 - 16 giờ",
  FOUR: "16 - 18 giờ",
  FIVE: "18 - 20 giờ",
  SIX: "19 - 21 giờ",
  SEVEN: "20 - 21 giờ",
};

export const CURRENCY_TYPE = {
  JPY: "JPY",
  VND: "VND",
};

export const ORDER_FROM_TYPE = {
  APP: "App",
  CMS: "CMS",
  OTHER: "Khác",
};

export const TRANSACTION_TYPE = {
  Daibiki: "Daibiki",
  cash: "GD cửa hàng",
  transfer: "Trả trước",
};

export const DISCOUNT_TYPE = {
  percentagePromotion: "Phần trăm(%)",
  promotion: "Tiền mặt",
};

export const MONEY_MANAGEMENT_TYPE = {
  spend: "Chi",
  collect: "Thu",
  cost: "Chi phí",
};

export const PRODUCT_STATE = {
  "Máy cũ": "Máy cũ",
  "Máy mới": "Máy mới",
};

export const GUARANTEE = {
  1: "1 tháng",
  2: "2 tháng",
  3: "3 tháng",
  4: "4 tháng",
  5: "5 tháng",
  6: "6 tháng",
  7: "7 tháng",
  8: "8 tháng",
  9: "9 tháng",
  10: "10 tháng",
  11: "11 tháng",
  12: "12 tháng",
  24: "24 tháng",
};

export const PROMOTION_TYPE = {
  PERCENT: "PERCENT",
  CASH: "CASH",
};

export const PROMOTION_TYPE_TEXT = {
  PERCENT: "Phần trăm (%)",
  CASH: "Tiền mặt",
};

export const ENTERTAINMENT_LIST = {
  active: {
    value: "active",
    label: "Hoạt động",
  },
  lock: {
    value: "lock",
    label: "Khoá",
  },
};

export const ENTERTAINMENT_MODAL_TYPE = {
  DETAIL: "DETAIL",
  CREATE: "CREATE",
  EDIT: "EDIT",
};

export const MARKET_PRODUCT_MODAL_TYPE = {
  DETAIL: "DETAIL",
  CREATE: "CREATE",
  EDIT: "EDIT",
};

export const MARKET_PRODUCT_UNIT_TYPE = [
  {
    label: "Kg",
    value: "Kg",
  },
  {
    label: "Gram",
    value: "Gram",
  },
  {
    label: "Gói",
    value: "Gói",
  },
  {
    label: "Chai",
    value: "Chai",
  },
  {
    label: "Hộp",
    value: "Hộp",
  },
  {
    label: "Thùng",
    value: "Thùng",
  },
  {
    label: "Bó",
    value: "Bó",
  },
  {
    label: "Lon",
    value: "Lon",
  },
  {
    label: "ML",
    value: "ML",
  },
  {
    label: "Quả",
    value: "Quả",
  },
  {
    label: "Lốc",
    value: "Lốc",
  },
  {
    label: "Hủ",
    value: "Hủ",
  },
  {
    label: "Lọ",
    value: "Lọ",
  },
  {
    label: "Cái",
    value: "Cái",
  },
  {
    label: "Con",
    value: "Con",
  },
  {
    label: "Bắp",
    value: "Bắp",
  },
  {
    label: "Phần",
    value: "Phần",
  },
  {
    label: "Vỉ",
    value: "Vỉ",
  },
  {
    label: "Túi",
    value: "Túi",
  },
  {
    label: "Khay",
    value: "Khay",
  },
];

export const ARTICLE_PRODUCT_MODAL_TYPE = {
  DETAIL: "DETAIL",
  CREATE: "CREATE",
  EDIT: "EDIT",
};

export const RESTAURANT_PRODUCT_UNIT_TYPE = {
  KG: "Kg",
  GRAM: "Gram",
  PACKAGE: "Gói",
  BOTTLE: "Chai",
};

export const DEFAULT_CONTACT = "06-6537-7297";

export const DEFAULT_ADDRESS = "大阪府大阪市浪速区日本橋3丁目5-29-2F";

export const GUIDE =
  "1) Đối với khách hàng ở Osaka hoặc các tỉnh lân cận có thể giao dịch trực tiếp tại cửa hàng Fonehouse.\nĐịa chỉ : 大阪府大阪市浪速区日本橋3丁目5-29-２F\nHoặc có thể tìm trên bản đồ với từ khóa Fonehouse.\n2) Với những khách hàng ở xa hoặc không tới cửa hàng được :\n +) Chuyển tiền trước vào tài khoản ngân hàng của Fonehouse. Sau khi nhận được tiền chúng tôi sẽ tiến hành gửi máy đi. Miễn phí gửi toàn quốc.\n +) Dịch vụ Daibiki thanh toán tại nhà cho nhân viên giao hàng rồi nhận hàng. Phí vận chuyển 1000 Yên. \nLưu ý: Đối với khách hàng ở vùng Okinawa, Hokkaido hoặc các vùng biển đảo thời gian vận chuyển 3-5 ngày, Phí vận chuyển 1500yen. Các vùng còn lại thời gian vận chuyển từ 1-2 ngày.\nXin Cảm Ơn Quý Khách Hàng !";
export const TIME_FORMAT = "HH:mm";

export const BANNER_STATUS = {
  ACTIVE: true,
  DE_ACTIVE: false,
};

export const BANNER_STATUS_TEXT = {
  [BANNER_STATUS.ACTIVE]: "Active",
  [BANNER_STATUS.DE_ACTIVE]: "De-active",
};

export const BANNER_TYPE = {
  home_1: "home_1",
  home_2: "home_2",
  home_3: "home_3",
  community_1: "community_1",
  community_2: "community_2",
  buy_now: "buy_now",
  hot: "hot",
  sim: "sim",
  sim_2: "sim_2",
  fonehouse: "fonehouse",
};

export const BANNER_TYPE_POSITION = {
  [BANNER_TYPE.home_1]: "Trang chủ - 1",
  [BANNER_TYPE.home_2]: "Trang chủ - 2",
  [BANNER_TYPE.home_3]: "Trang chủ - 3",
  [BANNER_TYPE.community_1]: "Cộng đồng - 1",
  [BANNER_TYPE.community_2]: "Cộng đồng - 2",
  [BANNER_TYPE.buy_now]: "Banner xem mua luôn",
  [BANNER_TYPE.hot]: "Banner sản phẩm hot",
  [BANNER_TYPE.sim]: "SIM",
  [BANNER_TYPE.sim_2]: "SIM 2",
  [BANNER_TYPE.fonehouse]: "Trang Fonehouse",
};

export const BANNER_TYPE_OPTIONS = {
  home_1: "Trang chủ - 1",
  home_2: "Trang chủ - 2",
  home_3: "Trang chủ - 3",
  community_1: "Cộng đồng - 1",
  community_2: "Cộng đồng - 2",
  buy_now: "Banner xem mua luôn",
  hot: "Banner sản phẩm hot",
  sim: "SIM",
  sim_2: "SIM - 2",
  fonehouse: "Trang Fonehouse",
};

export const BANNER_TYPE_VERTICAL_OPTIONS = {
  buy_now: "Banner xem mua luôn",
  hot: "Banner sản phẩm hot",
};

export const BANNER_TYPE_HORIZONTAL_OPTIONS = {
  home_1: "Trang chủ - 1",
  home_2: "Trang chủ - 2",
  home_3: "Trang chủ - 3",
  community_1: "Cộng đồng - 1",
  community_2: "Cộng đồng - 2",
  sim: "SIM",
  sim_2: "SIM - 2",
  fonehouse: "Trang Fonehouse",
};

export const ACTIVE_BANNER_CONTENT = {
  title: "Active the banner?",
  content: "Banner sẽ xuất hiện ở vị trí đã chọn",
  cancelText: "Hủy",
  okText: "Xác nhật",
};

export const DEACTIVE_BANNER_CONTENT = {
  title: "Deactivate the banner?",
  content: "Banner sẽ không xuất hiện trên app",
  cancelText: "Hủy",
  okText: "Xác nhật",
};

export const DELETE_BANNER_CONTENT = {
  title: "Xóa banner?",
  content: "Banner sẽ bị xóa khỏi danh sách",
  cancelText: "Hủy",
  okText: "Xác nhật",
};

export const MEMORY_TYPE = {
  GB: "GB",
  TB: "TB",
};

export const OS_TYPE = {
  IOS: "IOS",
  ANDROID: "Android",
};

export const FIREBASE_ERROR = {
  "auth/invalid-phone-number-login":
    "Số điện thoại không hợp lệ. Hãy kiểm tra và thử lại",
  "auth/invalid-phone-number":
    "Số điện thoại không hợp lệ. Hãy kiểm tra và thử lại",
  "auth/invalid-verification-code": "Mã xác thực không chính xác",
  "auth/too-many-requests":
    "Số điện thoại này đã bị chặn vì xác thực quá nhiều. Hãy thử lại sau 4 tiếng.",
  "auth/code-expired": "Mã SMS đã hết hạn. Hãy gửi lại để thử lại.",
};

export const MOMENT_FORMAT = {
  MONTH_YEAR_SHORT: "MM/YY",
  YEAR_MONTH_DATE: "YYYY/MM/DD",
  YEAR_MONTH: "YYYY/MM",
  YEAR_MONTH_DASH: "YYYY-MM",
  YEAR_MONTH_DATE_HOUR: "YYYY/MM/DD HH:mm",
  YEAR_MONTH_DATE_JP: "YYYY年MM月DD日",
  YEAR_MONTH_DATE_HOUR_JP: "YYYY年MM月DD日 HH:mm",
};

export const NOTIFICATION_LIST_LIMIT = 10;

export const NOTIFICATION_TYPE = {
  REACTION: "reaction",
  MESSAGE: "message",
  FOLLOW_RESTAURANT: "followRestaurant",
  FOLLOW_ENTERTAINMENT: "followentertainment",
  FOLLOW_SUPERMARKET: "followsupermarket",
  CANCEL_ORDER: "cancelOrder",
  SHIPPED_ORDER: "shippedOrder",
};

export const WORKING_DATE_LIST = [
  {
    label: "Thứ hai",
    value: 0,
  },
  {
    label: "Thứ ba",
    value: 1,
  },
  {
    label: "Thứ tư",
    value: 2,
  },
  {
    label: "Thứ năm",
    value: 3,
  },
  {
    label: "Thứ sáu",
    value: 4,
  },
  {
    label: "Thứ bảy",
    value: 5,
  },
  {
    label: "Chủ nhật",
    value: 6,
  },
];

export const WORKING_DATE_TEXT = {
  0: "Thứ hai",
  1: "Thứ ba",
  2: "Thứ tư",
  3: "Thứ năm",
  4: "Thứ sáu",
  5: "Thứ bảy",
  6: "Chủ Nhật",
};

export const PRODUCT_ARTICLE_TAG_TEXT = {
  [PRODUCT_TAG.HIGHLIGHTS]: "Nổi bật",
  [PRODUCT_TAG.BUY_NOW]: "Mua ngay",
  [PRODUCT_TAG.HOT]: "HOT",
  [PRODUCT_TAG.EMPTY]: "Trống",
};

export const COSMETIC_TYPE = {
  hair: "Tóc",
  spa: "Spa",
};

export const WORKING_TIME_OPTIONS = [
  {
    title: "Không có giờ làm việc",
    content: "Khách truy cập sẽ không thấy thời gian làm việc của cửa hàng",
    value: "no_time",
  },
  {
    title: "Luôn mở cửa",
    content: "Cửa hàng này mở cửa 24/7",
    value: "always_open",
  },
  {
    title: "Đóng cửa vĩnh viễn",
    content: "Cửa hàng này đóng cửa vĩnh viễn",
    value: "always_close",
  },
  {
    title: "Mở cửa vào khung giờ đã chọn",
    content: "Tự nhập giờ đóng - mở cửa",
    value: "custom",
  },
];

export const WORKING_TIME_TEXT = {
  no_time: "Không có giờ làm việc",
  always_open: "Luôn mở cửa",
  always_close: "Đóng cửa vĩnh viễn",
  custom: "Mở cửa vào khung giờ đã chọn",
};

export const STORE_TYPE = {
  RESTAURANT: "restaurants",
  MARKET: "markets",
  ENTERTAINMENT: "entertainments",
  COSMETIC: "cosmetics",
  CAR_AND_OLD_STUFF: "car_and_old_stuff",
  TRAVEL: "travel",
  SHIPPER: "shipper",
};

export const STORE_SUB_TYPE = {
  hair: "Tóc",
  spa: "Spa",
  CAR: "Xe",
  OLD_STUFF: "Đồ cũ",
  karaoke: "Karaoke",
  game: "Game",
  club: "Club",
  other: "Khác",
};
